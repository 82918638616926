var BrowseByIndustry = {

    constants: {
        $lvl1List: $('.BrowseByIndustry-level1List'),
        $lvl1Btn: $(".BrowseByIndustry-level1Btn"),
        $navLeft: $('.BrowseByIndustry-navigate--left'),
        $navRight: $('.BrowseByIndustry-navigate--right'),
        isActiveClass: "is-active",
        isHiddenClass: "is-hidden", 
        isVisibleClass: "is-visible"
    },

    showHide: function () {
        $(".BrowseByIndustry").each(function () {
            const $lvl1Btn = BrowseByIndustry.constants.$lvl1Btn,
                isActiveClass = BrowseByIndustry.constants.isActiveClass,
                isVisibleClass = BrowseByIndustry.constants.isVisibleClass;

            $lvl1Btn.on("click",
                function () {
                    var $this = $(this),
                        $targetEl = $($this.data("target"));

                    if ($this.hasClass(isActiveClass)) {
                        BrowseByIndustry.collapseAll();
                        return false;
                    }
                    BrowseByIndustry.collapseAll();

                    $this.addClass(isActiveClass);
                    $targetEl.addClass(isVisibleClass);
                    return true;
                });
        });
    },

    collapseAll: function () {
        const $lvl1Btn = BrowseByIndustry.constants.$lvl1Btn,
            isActiveClass = BrowseByIndustry.constants.isActiveClass,
            isVisibleClass = BrowseByIndustry.constants.isVisibleClass;

        $lvl1Btn.each(function () {
            let $this = $(this);
            $this.removeClass(isActiveClass);
            $this.closest(".BrowseByIndustry").find(".BrowseByIndustry-level2List").removeClass(isVisibleClass);
        });
    },
    setupScroll: function () {
        const $navLeft = BrowseByIndustry.constants.$navLeft,
            $navRight = BrowseByIndustry.constants.$navRight,
            $lvl1List = BrowseByIndustry.constants.$lvl1List;

        $navRight.click(function () {
            $lvl1List.animate({
                scrollLeft: "+=500"
            }, 200, function () {
                BrowseByIndustry.showHideNavButtons();
            });
        });

        $navLeft.click(function () {
            $lvl1List.animate({
                scrollLeft: "-=500"
            }, 200, function () {
                BrowseByIndustry.showHideNavButtons();
            });
        });
    },
    showHideNavButtons: function () {
        const $lvl1List = BrowseByIndustry.constants.$lvl1List,
            scrollLeft = $lvl1List.scrollLeft(),
            scrollWidth = $lvl1List[0].scrollWidth,
            scrollOuter = $lvl1List.outerWidth(),
            $navLeft = BrowseByIndustry.constants.$navLeft,
            $navRight = BrowseByIndustry.constants.$navRight,
            isHiddenClass = BrowseByIndustry.constants.isHiddenClass;

        if (scrollWidth <= scrollOuter) {
            $navRight.addClass(isHiddenClass);
            $navLeft.addClass(isHiddenClass);
        }
        else if (scrollLeft === 0) {
            $navLeft.addClass(isHiddenClass);
            $navRight.removeClass(isHiddenClass);
        }
        else if (scrollWidth - scrollLeft === scrollOuter) {
            $navRight.addClass(isHiddenClass);
            $navLeft.removeClass(isHiddenClass);
        }
        else {
            $navRight.removeClass(isHiddenClass);
            $navLeft.removeClass(isHiddenClass);
        }

    },

    init: function () {
        BrowseByIndustry.showHide();
        BrowseByIndustry.setupScroll();
        BrowseByIndustry.showHideNavButtons();
    }

}
$(function () {
    BrowseByIndustry.init();
});
